import { QueryOptions } from '@apollo/client';
import { gql } from '@/__generated__/gateway';
import {
  ContextInput,
  CountryConfigsQuery,
  CountryConfigsQueryVariables,
  CustomerAccessTokenQuery,
  CustomerAccessTokenQueryVariables,
  GetCountriesDataQueryQuery,
  GetCountriesDataQueryQueryVariables,
  GetNotificationsInput,
  NotificationsQuery,
  NotificationsQueryVariables,
  PatternBySkuQuery,
  PatternBySkuQueryVariables,
  PostCountersQuery,
  PostCountersQueryVariables,
  StarterDataQuery,
  StarterDataQueryVariables
} from '@/__generated__/gateway/graphql';

export const customerAccessTokenQuery = gql(`
  query CustomerAccessToken($storeName: String!) {
    shopify {
      exchangeToken(store_name: $storeName) {
        customerAccessToken {
          accessToken
          expiresAt
        }
      }
    }
  }
`);

export const queryCustomerAccessToken = (
  storeName: string
): QueryOptions<
  CustomerAccessTokenQueryVariables,
  CustomerAccessTokenQuery
> => ({
  query: customerAccessTokenQuery,
  fetchPolicy: 'no-cache',
  variables: { storeName }
});

export const queryStarterData = (
  input: GetNotificationsInput,
  alias: string,
  userId: string
): QueryOptions<StarterDataQueryVariables, StarterDataQuery> => ({
  query: gql(`
    query StarterData(
      $input: GetNotificationsInput!
      $alias: String!
      $userId: ID!
    ) {
      notification {
        notifications(input: $input) {
          unread
          unseen
        }
      }
      toc {
        status(alias: $alias) {
          success
        }
      }
      community {
        profile(user_id: $userId) {
          followers_count
          following_count
          user {
            id
            bio
            username
            display_name
            country
            avatar_url
          }
        }
      }
    }
  `),
  fetchPolicy: 'no-cache',
  variables: { input, alias, userId }
});

export const notificationsQuery = gql(`
  query Notifications($input: GetNotificationsInput!) {
    notification {
      notifications(input: $input) {
        unread
        unseen
        total
        cursor
        notifications {
          id
          message
          read_at
          created_at
          actor {
            display_name
            avatar_url
          }
          sourceable_type
          sourceable_id
          subject {
            ... on Post {
              id
            }
            ... on Comment {
              id
              post_id
            }
          }
        }
      }
    }
  }
`);

export const queryNotifications = (
  input: GetNotificationsInput
): QueryOptions<NotificationsQueryVariables, NotificationsQuery> => ({
  query: notificationsQuery,
  fetchPolicy: 'no-cache',
  variables: { input }
});

export const queryCountryConfigs = (): QueryOptions<
  CountryConfigsQueryVariables,
  CountryConfigsQuery
> => ({
  query: gql(`
    query countryConfigs {
      countryConfigs {
        countryCode
        shopifyConfig {
          storefront {
            endpoint
            name
            publicAccessToken
          }
          storeEndpoint
        }
      }
    }
  `),
  fetchPolicy: 'no-cache',
  variables: {}
});

export const customerContentQuery = gql(`
  query customerContent {
    customerContent {
      content {
        ... on Pattern {
          id
          sku
          url
          createdAt
          updatedAt
        }
      }
    }
  }
`);

export const queryTabCounters = (variables: {
  galleryContext: ContextInput;
  qaContext: ContextInput;
}): QueryOptions<PostCountersQueryVariables, PostCountersQuery> => ({
  query: gql(`
    query PostCounters($galleryContext: ContextInput, $qaContext: ContextInput) {
      community {
        gallery: posts(context: $galleryContext) {
          total
        }
        qa: posts(context: $qaContext) {
          total
        }
      }
    }
  `),
  variables
});

export const loyaltyPointsQuery = gql(`
  query loyalty {
    loyalty {
      points {
        cursor
        limit
        points {
          amount
          created_at
          type
          description
        }
      }
    }
  }
`);

export const queryPatternBySku = (
  sku: string,
  language = 'en',
  urlVersion = '2024-07-05',
  contactInfo?: {
    contactUUID?: string;
    countryCode?: string;
  }
): QueryOptions<PatternBySkuQueryVariables, PatternBySkuQuery> => ({
  query: gql(`
    query PatternBySku(
      $sku: String!
      $urlVersion: String
      $language: String
      $contactUUID: String
      $countryCode: String
    ) {
      patternBySku(
        sku: $sku
        urlVersion: $urlVersion
        language: $language
        contactUUID: $contactUUID
        countryCode: $countryCode
      ) {
        url
        sku
        id
        createdAt
        updatedAt
      }
    }
  `),
  fetchPolicy: 'no-cache',
  variables: {
    sku: sku,
    language: language,
    urlVersion: urlVersion,
    contactUUID: contactInfo?.contactUUID,
    countryCode: contactInfo?.countryCode
  }
});

export const queryCountryData = (): QueryOptions<
  GetCountriesDataQueryQueryVariables,
  GetCountriesDataQueryQuery
> => ({
  query: gql(`
    query GetCountriesDataQuery {
      countryConfigs {
        countryCode
        shopifyConfig {
          storeEndpoint
        }
        legacyStore {
          id
          name
          country
          country_switcher_allowed
          is_international
          language
          url
          currency
        }
      }
    }
  `),
  fetchPolicy: 'no-cache',
  variables: {}
});

export const countryBingoIdQuery = gql(`
  query BingoId($countryConfigInput: CountryConfigInput) {
    countryConfigs(countryConfigInput: $countryConfigInput) {
      bingoId
    }
  }
`);

export const crafterProjectsQuery = gql(`
  query CrafterProjects($cursor: String, $limit: Int) {
    crafter {
      userProjects(cursor: $cursor, limit: $limit) {
        projects {
          id
          name
          craft_type
          image {
            name
            url
            alt
          }
          note {
            id
            content
            created_at
            updated_at
          }
          state
          created_at
          ends_at
          started_at
          updated_at
        }
        limit
        cursor
      }
    }
  }
`);

export const crafterProjectQuery = gql(`
  query CrafterProject($projectId: ID!) {
    crafter {
      userProject(id: $projectId) {
        id
        name
        state
        image {
          alt
          name
          url
        }
        created_at
        updated_at
        note {
          id
          rows
          content
          created_at
          updated_at
        }
      }
    }
  }
`);
