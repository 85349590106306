import { MoneyV2 } from '@/__generated__/storefront/graphql';

export const MONEY_FORMAT_PLACEHOLDERS = {
  AMOUNT: 'amount',
  AMOUNT_NO_DECIMALS: 'amount_no_decimals',
  AMOUNT_WITH_COMMA_SEPARATOR: 'amount_with_comma_separator',
  AMOUNT_NO_DECIMALS_WITH_COMMA_SEPARATOR:
    'amount_no_decimals_with_comma_separator',
  AMOUNT_WITH_APOSTROPHE_SEPARATOR: 'amount_with_apostrophe_separator'
} as const;

export const VALUE_NOT_REACHED_FALLBACK = '-';
const FALLBACK_MONEY_FORMAT = '${{amount}}';
const PLACEHOLDER_REGEX = /\{\{\s*(\w+)\s*\}\}/;
const THOUSAND_SEPARATOR_REGEX = /(\d)(?=(\d{3})+(?!\d))/g;

export const formatPriceStorefront = (price?: MoneyV2) => {
  const cents = parseFloat(price?.amount || '0') * 100;
  return formatMoney(cents, window.Hobbii?.moneyFormat);
};

export const formatMoney = (cents: number, format?: string): string => {
  if (typeof cents !== 'number' || Number.isNaN(cents)) {
    if (import.meta.env.DEV) {
      throw new Error(
        `formatMoney: cents argument not of accepted format ${JSON.stringify(
          cents
        )}, must be a number.`
      );
    }

    return VALUE_NOT_REACHED_FALLBACK;
  }

  const formatString = format ?? FALLBACK_MONEY_FORMAT;

  const targetPlaceholderMatch = formatString.match(PLACEHOLDER_REGEX);
  const targetPlaceholder = targetPlaceholderMatch?.[1];

  // NOTE: we could fall back to `Intl.NumberFormat` instead of defaulting to ${{amount}}':
  // new Intl.NumberFormat(`${window.Shopify.locale}-${window.Shopify.country}`, { style: 'currency', currency: window.Shopify.currency.active}).format(1000000)
  const formattedValue = targetPlaceholder
    ? formatWithPlaceholder(cents, targetPlaceholder)
    : formatWithDelimiters(cents);

  return formatString.replace(PLACEHOLDER_REGEX, formattedValue);
};

const formatWithPlaceholder = (
  cents: number,
  targetPlaceholder: string
): string => {
  switch (targetPlaceholder) {
    case MONEY_FORMAT_PLACEHOLDERS.AMOUNT:
      return formatWithDelimiters(cents);
    case MONEY_FORMAT_PLACEHOLDERS.AMOUNT_NO_DECIMALS:
      return formatWithDelimiters(cents, 0);
    case MONEY_FORMAT_PLACEHOLDERS.AMOUNT_WITH_COMMA_SEPARATOR:
      return formatWithDelimiters(cents, 2, '.', ',');
    case MONEY_FORMAT_PLACEHOLDERS.AMOUNT_NO_DECIMALS_WITH_COMMA_SEPARATOR:
      return formatWithDelimiters(cents, 0, '.', ',');
    case MONEY_FORMAT_PLACEHOLDERS.AMOUNT_WITH_APOSTROPHE_SEPARATOR:
      return formatWithDelimiters(cents, 2, "'", '.');
  }

  if (import.meta.env.DEV) {
    throw new Error(
      `formatMoney: No match for placeholder "${targetPlaceholder}" found when formatting "${cents}" cents.`
    );
  }

  // NOTE: default to returning MONEY_FORMAT_PLACEHOLDERS.AMOUNT in prod.
  // This will default to "{{amount}} bananas" for stuff like "{{bananas}} bananas"
  return formatWithDelimiters(cents);
};

const formatWithDelimiters = (
  cents: number,
  precision = 2,
  thousands = ',',
  decimal = '.'
): string => {
  const numberStr = (Math.round(cents) / 100).toFixed(precision);
  const [wholePart, decimalPart] = numberStr.split('.');

  const wholePartFormatted = wholePart.replace(
    THOUSAND_SEPARATOR_REGEX,
    `$1${thousands}`
  );

  //
  const decimalPartFormatted =
    decimalPart && decimalPart !== '00' ? decimal + decimalPart : '';

  return wholePartFormatted + decimalPartFormatted;
};
